<template>
  <div class="tree">
    <!-- 1 目录，2 素材， 3 考试，4 直播，5 实训, 6 表单，7练习,8线下实训 9 课程 -->
    <el-tree
      :data="data"
      node-key="id"
      default-expand-all
      ref="tree"
      :default-expanded-keys="[lastPlayItemId]"
      @node-click="handleNodeClick"
      @node-expand="nodeExpandClick"
      :props="defaultProps"
    >
      <!-- data -->
      <div class="custom-tree-node" slot-scope="{ data }">
        <p :title="data.contentName" class="label1">
          <!-- 内容类型 -->
          <!-- :class="ConstData.taskType[data.type].type" -->
          <span
            class="type"
            v-if="data.type != 1 && data.type != 2 && data.type"
            >{{ ConstData.taskType[data.type].text }}</span
          >
          <!-- 素材类型 -->
          <!-- 直播处理TODO -->
          <!-- :class="ConstData.courseType[data.contentType].type" -->
          <span
            class="type"
            v-if="data.contentType"
            >{{ ConstData.courseType[data.contentType].text }}</span
          >
     
          <span class="name">{{
            data.stageName || data.contentName
          }}</span>
        </p>
        <!-- {{ data }} -->
        <!-- 右边信息 -->
        <div class="rightInfo rightWidth320">
          <!-- 素材信息 -->
          <template v-if="[1, 2, 3, 4].includes(data.contentType)">
            <span v-if="[1].includes(+data.contentType) && data.tryWatch && !goodsInfo.isCanStudy" class="tryWatch">
              <svg-icon icon-class="play"></svg-icon>
              试看
            </span>
            <!-- 时长 视频音频 -->
            <span class="timeVal" v-if="[1, 2].includes(data.contentType)"
              >时长 · {{ minutes(data.duration) }}分{{
                seconds(data.duration)
              }}秒</span>
            <!-- 学习进度百分比 -->
            <span
              class="highLight"
              v-if="data.studyInfo && data.studyInfo.learnRate"
            >
              {{ data.studyInfo.learnRate || 0 }}%
            </span>
          </template>
          <!-- 考试 直播 -->
          <template v-if="[3].includes(data.type)"> </template>
          <template v-if="data.type === 4">
            <p class="rightWidth320-time" v-if="data.contentStartTime">
              开始时间·{{ data.contentStartTime }}
            </p>
          </template>
        </div>
        <!-- <div class="lock-icon" v-show="data.isLocked">
            <svg-icon icon-class="lock"></svg-icon>
          </div> -->
      </div>
    </el-tree>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    taskId: {
      type: Number,
      default: 0,
    },
    goodsInfo:{
      type:Object,
      default: () => {
        return {};
      },
    }
  },
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "contentName",
      },
      lastPlayItemId: "",
      // liveCourseLive,
      // liveCouresBack,
    };
  },
  watch: {
    taskId(val) {
      this.quickLearn(val);
    },
  },
  created() {
    console.log(this.data, "this.data++++++++");
  },
  /* eslint-disable */
  methods: {
    quickLearn(val) {
      const params = {
        taskId: val,
      };
      console.log(params);
      return this.$api.learn.quickLearn({ params }).then((res) => {
        if (res.data) {
          this.lastPlayItemId = res.data.lastPlayItemId;
        }
      });
    },
    // 节点点击
    handleNodeClick(data) {
      this.$emit("clickNode", data);
      console.log(data, "data====");
    },
    minutes(duration) {
      let minutes = Math.floor(duration / 60);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      return minutes;
    },
    seconds(duration) {
      let seconds =parseInt(duration % 60) ;
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return seconds;
    },
    nodeExpandClick(data, node) {
      // this.updateChildren(node)
    },
    updateChildren(list) {
      if (list.childNodes.length > 0) {
        list.childNodes.forEach((item) => {
          item.expanded = true;
          this.updateChildren(item);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep{
  .custom-tree-node {
    white-space: normal;
  }
}
.rightWidth320{
  width: 320px !important;
  display: flex;
  align-items: center !important;
  justify-content: flex-end !important;
}
.rightInfo{
  .tryWatch{
    margin-left: 18px;
  }
}
</style>
